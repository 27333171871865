(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/view-header/assets/javascripts/view-header.js') >= 0) return;  svs.modules.push('/components/marketplace/view-header/assets/javascripts/view-header.js');

'use strict';

const {
  TextExpander
} = svs.components.lbUi.textExpander;
const {
  Receipt
} = svs.components.marketplace.viewHeader;
const {
  Loader
} = svs.components.ReactLoader;
const {
  Viewer
} = svs.components.marketplaceRetailAdmin.richText;
const {
  ReactIcon
} = svs.ui;
const {
  MarketplaceBrand
} = svs.components.marketplace.marketplaceBrand;
const {
  RibbonBanner
} = svs.ui.ReactRibbon;
const {
  showFewSharesLeftRibbon
} = svs.components.marketplace.helpers.games;
const ViewHeader = _ref => {
  let {
    productId,
    subProductId,
    boldDescription,
    description = '',
    richDescription = null,
    isFavorite = null,
    receiptFields = [],
    showLoader = false,
    subTitle = '',
    title = '',
    gameTag,
    teamName,
    onGoBack,
    hasCloseButton,
    onGoToTeam,
    game,
    groupId
  } = _ref;
  const subTitles = Array.isArray(subTitle) ? subTitle : [subTitle];
  const showFewSharesLeft = showFewSharesLeftRibbon(game, groupId);
  const sharesLeft = (game === null || game === void 0 ? void 0 : game.maxFractions) - (game === null || game === void 0 ? void 0 : game.soldShares);
  return React.createElement("div", {
    className: "view-header-container",
    "data-testid": "view-header"
  }, React.createElement("div", {
    className: "view-header-back-container"
  }, Boolean(onGoBack) && hasCloseButton ? React.createElement("div", {
    className: "back-button-container"
  }, React.createElement("button", {
    className: "qa-back-button icon-button",
    onClick: onGoBack,
    type: "button"
  }, React.createElement(ReactIcon, {
    icon: "close",
    size: "300"
  }))) : Boolean(onGoBack) && (React.createElement("div", {
    className: "back-button-container"
  }, React.createElement("button", {
    className: "qa-back-button back-button",
    onClick: onGoBack,
    type: "button"
  }, React.createElement(ReactIcon, {
    icon: hasCloseButton ? 'close' : 'arrow-left',
    size: "300"
  }))) || null), React.createElement("button", {
    className: "qa-team-link team-link",
    onClick: onGoToTeam,
    type: "button"
  }, teamName)), React.createElement("div", {
    className: "view-header"
  }, React.createElement("div", {
    className: "view-header-header"
  }, showLoader && React.createElement("div", {
    className: "view-header-loader"
  }, React.createElement(Loader, {
    inverted: true,
    size: 100
  })), React.createElement("div", {
    className: "view-header-header-content"
  }, React.createElement("span", {
    className: "view-header-brand"
  }, React.createElement(MarketplaceBrand, {
    productId: productId,
    square: true,
    subProductId: subProductId
  })), React.createElement("div", {
    className: "view-header-titles-container"
  }, gameTag, React.createElement("div", {
    className: "f-medium block f-uppercase f-600 view-header-title"
  }, title), subTitles.map(subTitle => React.createElement("div", {
    className: "f-uppercase fc-grey-500 f-200 view-header-sub-title",
    key: subTitle
  }, subTitle))), isFavorite !== null && React.createElement(ReactIcon, {
    class: "view-header-favorite-icon",
    icon: isFavorite ? 'star' : 'star-empty',
    size: "300"
  })), boldDescription && React.createElement("div", {
    className: "f-bold margin-top margin-bottom qa-bold-description"
  }, boldDescription) || null, richDescription ? React.createElement(Viewer, {
    richDescription: richDescription
  }) : React.createElement(TextExpander, {
    description: description,
    maxLength: 140
  })), React.createElement("div", {
    className: "view-header-receipt-wrapper"
  }, showFewSharesLeft && React.createElement(RibbonBanner, {
    direction: "left",
    size: "300"
  }, "".concat(sharesLeft, " andel").concat(sharesLeft > 1 ? 'ar' : '', " kvar!")), receiptFields.length > 0 && React.createElement(Receipt, {
    receiptFields: receiptFields
  }))));
};
setGlobal('svs.components.marketplace.viewHeader.ViewHeader', ViewHeader);

 })(window);